import { SidenavItemInterface } from '../../shared/layouts/side-nav/interfaces/side-nav-items.interface';
import SubSidenavPermissions from "./grentSubSidenavPermissions"
const grentLinks: SidenavItemInterface[] = [];
const grentSettingsLink = [
  {
    id: 'grent',
    label: 'settings.grent',
    isActive: false,
    expanded: false,
    items: [
      {
        id: 'automationRule',
        label: 'grent.automationRule',
        isActive: false,
        expanded: false,
        routerLink: 'grent/automationRule',
        permissions: ['VIEW_GRENT_AUTOMATION_RULE_ALL', 'VIEW_GRENT_AUTOMATION_RULE_OWN'],
      },
      {
        id: 'residualValue',
        label: 'grent.residualValue',
        isActive: false,
        expanded: false,
        routerLink: 'grent/residual-rule',
        permissions: SubSidenavPermissions.residualValue
      },
      {
        id: 'OptionalRV',
        label: 'grent.optionalRV',
        isActive: false,
        expanded: false,
        routerLink: 'grent/optional',
        permissions: SubSidenavPermissions.OptionalRV
      },
      {
        id: 'discount',
        label: 'grent.discount',
        isActive: false,
        expanded: false,
        routerLink: 'grent/discount',
        permissions: SubSidenavPermissions.discount
      },
      {
        id: 'insurance',
        label: 'settings.insurance',
        isActive: false,
        expanded: false,
        routerLink: 'grent/insurance',
        permissions: SubSidenavPermissions.insurance
      },
      {
        id: 'maintenance',
        label: 'grent.maintenance',
        isActive: false,
        expanded: false,
        routerLink: 'grent/maintenance',
        permissions: SubSidenavPermissions.maintenance
      },
      /*{
        id: 'maintenance',
        label: 'grent.maintenance',
        isActive: false,
        expanded: false,
        routerLink: 'grent/maintenanceV2/general',
      },*/
      {
        id: 'pneumatic',
        label: 'grent.pneumatic',
        isActive: false,
        expanded: false,
        routerLink: 'grent/pneumatic',
        permissions: SubSidenavPermissions.pneumatic
      },
      {
        id: 'ipt',
        label: 'grent.ipt',
        isActive: false,
        expanded: false,
        routerLink: 'grent/ipt',
        permissions: ['VIEW_IPT'],
      },
      {
        id: 'mss',
        label: 'grent.mss',
        isActive: false,
        expanded: false,
        routerLink: 'grent/mss',
        permissions: SubSidenavPermissions.mss
      },
      {
        id: 'afterMarket',
        label: 'grent.afterMarket',
        isActive: false,
        expanded: false,
        routerLink: 'grent/afterMarket',
        permissions: ['VIEW_AFTER_MARKET_ALL', 'VIEW_AFTER_MARKET_OWN'],
      },
      {
        id: 'substituteVehicle',
        label: 'grent.substituteVehicle',
        isActive: false,
        expanded: false,
        routerLink: 'grent/substituteVehicle',
        permissions: [
          'VIEW_SUBSTITUTE_VEHICLE_ALL',
          'VIEW_SUBSTITUTE_VEHICLE_OWN',
        ],
      },
      {
        id: 'tan',
        label: 'grent.tan',
        isActive: false,
        expanded: false,
        routerLink: 'grent/tan',
        permissions: [ 'VIEW_TAN' ],
      },
      {
        id: 'streetAssistance',
        label: 'grent.streetAssistance',
        isActive: false,
        expanded: false,
        routerLink: 'grent/streetAssistance',
        permissions: ['VIEW_STREET_ASSISTANCE'],
      },
      {
        id: 'fuelCard',
        label: 'grent.fuelCard',
        isActive: false,
        expanded: false,
        routerLink: 'grent/fuelCard',
        permissions: ['VIEW_FUEL_CARD'],
      },
      {
        id: 'qutoreVehicle',
        label: 'grent.qutoreVehicle',
        isActive: false,
        expanded: false,
        routerLink: 'grent/qutoreVehicle',
        permissions: ['VIEW_QUOTER_VEHICLE_PRICE'],
      },
      {
        id: 'infoMobility',
        label: 'grent.infoMobility',
        isActive: false,
        expanded: false,
        routerLink: 'grent/infoMobility',
        permissions: ['VIEW_INFO_MOBILITY'],
      },
      {
        id: 'deliveryAndTransport',
        label: 'grent.deliveryAndTransport',
        isActive: false,
        expanded: false,
        routerLink: 'grent/deliveryAndTransport',
        permissions: ['VIEW_DELIVERY_AND_TRANSPORT'],
      },
    ],
  },
]
export default {grentLinks, grentSettingsLink};
