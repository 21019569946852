export default {
  residualValue: [
    'VIEW_STANDARD_KILOMETER',
    'VIEW_GENERAL_RESIDUAL_RULE', 'CREATE_GENERAL_RESIDUAL_RULE',
    'VIEW_BRAND_RESIDUAL_RULE',
    'VIEW_MODEL_RESIDUAL_RULE',
    'VIEW_VERSION_RESIDUAL_RULE'
  ],
  OptionalRV: [
    'VIEW_OPTIONAL_RV',
    'VIEW_GENERAL_OPTIONAL_RV'
  ],
  discount: [
    'VIEW_BRAND_DISCOUNT',
    'VIEW_MODEL_DISCOUNT',
    'VIEW_VERSION_DISCOUNT'
  ],
  insurance: [
    'VIEW_INSURANCE_STANDARD_KILOMETER',

    'VIEW_GENERAL_RCA',
    'VIEW_EXCESS_RCA_ALL', 'VIEW_EXCESS_RCA_OWN',

    'VIEW_GENERAL_FURTO_INCENDIO',
    'VIEW_EXCESS_FURTO_INCENDIO_ALL', 'VIEW_EXCESS_FURTO_INCENDIO_OWN',

     'VIEW_GENERAL_KASKO', 'VIEW_EXCESS_KASKO_ALL', 'VIEW_EXCESS_KASKO_OWN',
  ],
  maintenance: [
    'VIEW_GENERAL_GRENT_MAINTENANCE',
    'VIEW_BRAND_GRENT_MAINTENANCE', 'VIEW_MODEL_GRENT_MAINTENANCE',
    'VIEW_VERSION_GRENT_MAINTENANCE'
  ],
  pneumatic: [
    'VIEW_BRAND_PNEUMATIC',
    'VIEW_MODEL_PNEUMATIC',
    'VIEW_VERSION_PNEUMATIC'
  ],
  mss: [
    'VIEW_BRAND_MSS',
    'VIEW_MODEL_MSS',
    'VIEW_VERSION_MSS'
  ]
}
