import { SidenavItemInterface } from '../../shared/layouts/side-nav/interfaces/side-nav-items.interface';


const crmLinks: SidenavItemInterface[] =  [
  {
    id: 'leads',
    label: 'crm.leads',
    isActive: false,
    expanded: false,
    routerLink: '/crm/leads',
    permissions: ["VIEW_LEAD_ALL", "VIEW_LEAD_OWN"]
  },
  {
    id: 'customer',
    label: 'crm.customers',
    isActive: false,
    expanded: false,
    routerLink: '/crm/customer',
    permissions: ['VIEW_CUSTOMER_ALL', 'VIEW_CUSTOMER_OWN']
  },
  {
    id: 'driver',
    label: 'crm.driver',
    isActive: false,
    expanded: false,
    routerLink: '/crm/driver',
    permissions: ['VIEW_DRIVER_ALL', 'VIEW_DRIVER_OWN']
  },
  {
    id: 'supplier',
    label: 'crm.supplier',
    isActive: false,
    expanded: false,
    routerLink: '/crm/supplier',
    permissions: ['VIEW_SUPPLIER_ALL', 'VIEW_SUPPLIER_OWN']
  },
  {
    id: 'unblockCustomerRequest',
    label: 'crm.unblockCustomerRequest',
    isActive: false,
    expanded: false,
    routerLink: '/crm/unblock-customer-request',
    permissions: ['VIEW_UNBLOCK_REQUEST_ALL', 'VIEW_UNBLOCK_REQUEST_OWN']
  },
]
const crmSettingsLinks: SidenavItemInterface[] = [
  {
    id: 'crm',
    label: 'settings.crm',
    isActive: false,
    expanded: false,
    items: [
      {
        id: 'customerUnblockRules',
        label: 'crm.customerUnblockRules',
        isActive: false,
        expanded: false,
        routerLink: '/crm/customer-unblock-rules',
        permissions: ['VIEW_UNBLOCK_RULE'],
      },
      {
        id: 'conventions',
        label: 'crm.conventions',
        isActive: false,
        expanded: false,
        routerLink: '/crm/conventions',
        permissions: ['VIEW_CONVENTION_ALL', 'VIEW_CONVENTION_OWN'],
      },
      {
        id: 'types',
        label: 'settings.types',
        items: [
          {
            id: 'crmCustomerTypes',
            label: 'crm.customerTypes',
            isActive: false,
            expanded: false,
            routerLink: '/crm/customer-types',
            permissions: ['VIEW_CRM_CUSTOMER_TYPE_ALL', 'VIEW_CRM_CUSTOMER_TYPE_OWN'],
          },
          {
            id: 'supplierType',
            label: 'crm.supplierType',
            isActive: false,
            expanded: false,
            routerLink: '/crm/supplierType',
            permissions: ['VIEW_SUPPLIER_TYPE_ALL', 'VIEW_SUPPLIER_TYPE_OWN'],
          },
        ],
        isActive: false,
        expanded: false,
      },
      {
        id: 'industry-sector',
        label: 'crm.industrySector',
        isActive: false,
        expanded: false,
        routerLink: '/crm/industry-sector',
        permissions: ['VIEW_INDUSTRY_SECTOR_ALL', 'VIEW_INDUSTRY_SECTOR_OWN'],
      },
      {
        id: 'activity',
        label: 'crm.activity',
        isActive: false,
        expanded: false,
        routerLink: '/crm/activity',
        permissions: ['VIEW_ACTIVITY_ALL', 'VIEW_ACTIVITY_OWN'],
      },
      {
        id: 'tagsGroups',
        label: 'crm.tagsGroups',
        isActive: false,
        expanded: false,
        routerLink: '/crm/tagsGroups',
        permissions: ['VIEW_TAGS_GROUPS_ALL', 'VIEW_TAGS_GROUPS_OWN'],
      },
      {
        id: 'leadsPipeline',
        label: 'crm.leadsPipeline',
        isActive: false,
        expanded: false,
        routerLink: '/crm/leadsPipeline',
        permissions: ['VIEW_LEADS_PIPELINE_ALL', 'VIEW_LEADS_PIPELINE_OWN'],
      },
      {
        id: 'duplicationField',
        label: 'crm.duplicationField',
        isActive: false,
        expanded: false,
        routerLink: '/crm/duplicationField',
        permissions: [
          'VIEW_DUPLICATION_FIELD_ALL',
          'VIEW_DUPLICATION_FIELD_OWN',
        ],
      },
      {
        id: 'dynamicField',
        label: 'crm.dynamicField',
        isActive: false,
        expanded: false,
        routerLink: '/crm/dynamicField',
        permissions: ['VIEW_DYNAMIC_FIELD_ALL', 'VIEW_DYNAMIC_FIELD_OWN'],
      },
      {
        id: 'salesScript',
        label: 'crm.salesScript',
        isActive: false,
        expanded: false,
        routerLink: '/sales-script',
        permissions: ['VIEW_SALES_SCRIPT_ALL', 'VIEW_SALES_SCRIPT_OWN'],
      },
    ],
  },
]
export default {settingLinks: crmSettingsLinks, crmLinks};
