import { SidenavItemInterface } from 'src/app/shared/layouts/side-nav/interfaces/side-nav-items.interface';

const settingsFleetLongTermLinks: SidenavItemInterface[] = [
  {
    id: 'long-term',
    label: 'settings.long',
    isActive: false,
    expanded: false,
    items: [
      {
        id: 'stockType',
        label: 'flt.stockType',
        isActive: false,
        expanded: false,
        routerLink: 'fleet-management/stock-type',
        permissions: ['VIEW_STOCK_TYPE_ALL', 'VIEW_STOCK_TYPE_OWN'],
      },
      {
        id: 'stockCustomerType',
        label: 'flt.stockCustomerType',
        isActive: false,
        expanded: false,
        routerLink: 'fleet-management/stock-customer-type',
        permissions: [
          'VIEW_STOCK_CUSTOMER_TYPE_ALL',
          'VIEW_STOCK_CUSTOMER_TYPE_OWN',
        ],
      },
      {
        id: 'stockProduct',
        label: 'flt.stockProduct',
        isActive: false,
        expanded: false,
        routerLink: 'fleet-management/stock-product',
        permissions: ['VIEW_STOCK_PRODUCT_ALL', 'VIEW_STOCK_PRODUCT_OWN'],
      },
      {
        id: 'stockCategory',
        label: 'flt.stockCategory',
        isActive: false,
        expanded: false,
        routerLink: 'fleet-management/stock-category',
        permissions: ['VIEW_STOCK_CATEGORY_ALL', 'VIEW_STOCK_CATEGORY_OWN'],
      },
    ],
  },
];

const fleetLongTermLinks: SidenavItemInterface[] = [
  // {
  //   id: 'vehicle',
  //   label: 'flt.vehicle',
  //   isActive: false,
  //   expanded: false,
  //   routerLink: 'fleet/vehicle',
  //   permissions: ['VIEW_VEHICLE_LT_ALL', 'VIEW_VEHICLE_LT_OWN']
  // },
]
export default {fleetLongTermLinks, settingsFleetLongTermLinks};
