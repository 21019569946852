import { SidenavItemInterface } from 'src/app/shared/layouts/side-nav/interfaces/side-nav-items.interface';

const settingsConfig: SidenavItemInterface[] = [
  {
    id: 'invoiceConfig',
    label: 'settings.invoice',
    isActive: false,
    expanded: false,
    items: [
      {
        id: 'companyInvoice',
        label: 'settings.companyInvoice',
        isActive: false,
        expanded: false,
        routerLink: '/company-invoice',
        permissions: ['VIEW_COMPANY_INVOICE'],
      },
      {
        id: 'e-invoice',
        label: 'settings.e-invoice',
        isActive: false,
        expanded: false,
        routerLink: '/electronic-invoice',
        permissions: ['VIEW_ELECTRONIC_INVOICE'],
      },
      {
        id: 'balanceAccount',
        label: 'settings.balanceAccount',
        isActive: false,
        expanded: false,
        routerLink: '/balance-account',
        permissions: ['VIEW_BALANCE_ACCOUNT_ALL', 'VIEW_BALANCE_ACCOUNT_OWN'],
      },
      {
        id: 'paymentMethods',
        label: 'settings.paymentMethods',
        isActive: false,
        expanded: false,
        routerLink: '/payment-methods',
        permissions: ['VIEW_INVOICE_PAYMENT_ALL', 'VIEW_INVOICE_PAYMENT_OWN'],
      },
      {
        id: 'invoiceConfig',
        label: 'settings.invoiceConfig',
        isActive: false,
        expanded: false,
        routerLink: '/invoice-configuration',
        permissions: ['VIEW_INVOICE_CONFIGURATION'],
      },
      {
        id: 'taxManagement',
        label: 'settings.taxManagement',
        isActive: false,
        expanded: false,
        routerLink: '/tax-management',
        permissions: ['VIEW_INVOICE_TAX_ALL', 'VIEW_INVOICE_TAX_OWN'],
      },
      {
        id: 'currencyManagement',
        label: 'settings.currencyManagement',
        isActive: false,
        expanded: false,
        routerLink: '/currency-management',
          permissions: ['VIEW_INVOICE_CURRENCY_ALL', 'VIEW_INVOICE_CURRENCY_OWN'],
      },
    ],
  },
  {
    id: 'models',
    label: 'settings.modles',
    isActive: false,
    expanded: false,
    items: [
      {
        id: 'model',
        label: 'settings.model',
        isActive: false,
        expanded: false,
        routerLink: ' ',
      },
      {
        id: 'docs',
        label: 'settings.docs',
        isActive: false,
        expanded: false,
        routerLink: ' ',
      },
    ],
  },
  {
    id: 'logs',
    label: 'settings.logs',
    isActive: false,
    expanded: false,
    items: [
      {
        id: 'auditLog',
        label: 'settings.auditLog',
        isActive: false,
        expanded: false,
        routerLink: ' ',
      },
      {
        id: 'email',
        label: 'settings.email',
        isActive: false,
        expanded: false,
        routerLink: ' ',
      },
      {
        id: 'sms',
        label: 'settings.sms',
        isActive: false,
        expanded: false,
        routerLink: ' ',
      },
      {
        id: 'suppression',
        label: 'settings.suppression',
        isActive: false,
        expanded: false,
        routerLink: ' ',
      },
      {
        id: 'import',
        label: 'settings.import',
        isActive: false,
        expanded: false,
        routerLink: ' ',
      },
      {
        id: 'export',
        label: 'settings.export',
        isActive: false,
        expanded: false,
        routerLink: ' ',
      },
    ],
  },
  {
    id: 'rental',
    label: 'settings.rental',
    isActive: false,
    expanded: false,
    items: [
      {
        id: 'dynamic-field',
        label: 'settings.dynamic',
        isActive: false,
        expanded: false,
        routerLink: ' ',
      },
      {
        id: 'rules',
        label: 'settings.rules',
        isActive: false,
        expanded: false,
        routerLink: ' ',
      },
    ],
  },
  {
    id: 'integrations',
    label: 'settings.integrations',
    isActive: false,
    expanded: false,
    items: [],
  },
];
export default settingsConfig;
