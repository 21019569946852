import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from 'src/app/shared/layouts/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from 'src/app/shared/auth/guards/auth.guard';

/**
 * NOTE THAT THIS FILE SHOULD ONLY CONTAIN LAZY LOADING ROUTS
 * EACH ROUTE SHOULD POINT TO A MODULE THAT CONTAINS ITS OWN ROUTES
 */
const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'grent/residual-rule',
        loadChildren: () =>
          import('./modules/residual/residual.module').then(
            (m) => m.ResidualModule,
          ),
      },
      {
        path: 'grent/discount',
        loadChildren: () =>
          import('./modules/discount/discount.module').then(
            (m) => m.DiscountModule,
          ),
      },
      {
        path: 'grent/mss',
        loadChildren: () =>
          import('./modules/mss/mss.module').then((m) => m.MssModule),
      },
      /*{
        path: 'grent/maintenance',
        loadChildren: () =>
          import('./modules/maintenance/maintenance.module').then(
            (m) => m.MaintenanceModule,
          ),
      },*/
      {
        path: 'grent/maintenance',
        loadChildren: () =>
          import('./modules/maintenanceV2/maintenanceV2.module').then(
            (m) => m.MaintenanceV2Module,
          ),
      },
      {
        path: 'grent/pneumatic',
        loadChildren: () =>
          import('./modules/pneumatic/pneumatic.module').then(
            (m) => m.PneumaticModule,
          ),
      },
      {
        path: 'grent/tan',
        loadChildren: () =>
          import('./modules/tan/set-tan.module').then((m) => m.SetTanModule),
      },
      {
        path: 'grent/ipt',
        loadChildren: () =>
          import('./modules/ipt/set-ipt.module').then((m) => m.SetIptModule),
      },
      {
        path: 'grent/streetAssistance',
        loadChildren: () =>
          import(
            './modules/street-assistance/set-street-assistance.module'
          ).then((m) => m.SetStreetAssistanceModule),
      },
      {
        path: 'grent/afterMarket',
        loadChildren: () =>
          import('./modules/after-market/after-market.module').then(
            (m) => m.AfterMarketModule,
          ),
      },
      {
        path: 'grent/substituteVehicle',
        loadChildren: () =>
          import('./modules/substitute-vehicle/substitute-vehicle.module').then(
            (m) => m.SubstituteVehicleModule,
          ),
      },
      {
        path: 'grent/fuelCard',
        loadChildren: () =>
          import('./modules/fuel-card/set-fuel-card.module').then(
            (m) => m.SetFuelCardModule,
          ),
      },
      {
        path: 'grent/insurance',
        loadChildren: () =>
          import('./modules/insurance/insurance.module').then(
            (m) => m.InsuranceModule,
          ),
      },
      {
        path: 'grent/qutoreVehicle',
        loadChildren: () =>
          import(
            './modules/qutore-vehicle-price/get-qutore-vehicle-price.module'
          ).then((m) => m.GetQutoreVehiclePriceModule),
      },
      {
        path: 'grent/automationRule',
        loadChildren: () =>
          import('./modules/automation-rule/automation-rule.module').then(
            (m) => m.AutomationRuleModule,
          ),
      },
      {
        path: 'grent/optional',
        loadChildren: () =>
          import('./modules/optional-rv-module/optional-rv.module').then(
            (m) => m.optionalModule,
          ),
      },
      {
        path: 'grent/infoMobility',
        loadChildren: () =>
          import('./modules/info-mobility/set-info-mobility.module').then(
            (m) => m.SetInfoMobilityModule,
          ),
      },
      {
        path: 'grent/deliveryAndTransport',
        loadChildren: () =>
          import(
            './modules/delivery-and-transport/delivery-and-transport.module'
          ).then((m) => m.DeliveryAndTransportModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GrentRoutingModule {}
