import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appInfoStateReducers } from 'src/app/core/root-store/root.reducer';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { GraphqlModule } from 'src/app/kernel/graphql/graphql.module';
import { environment } from 'src/environments/environment';
import { RootEffects } from 'src/app/core/root-store/root.effects';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SideNavModule } from '../shared/layouts/side-nav/side-nav.module';
import { PopupWrapperModule } from '../kernel/popups/popup-wrapper/popup-wrapper.module';
import { SideNavService } from '../shared/layouts/side-nav/services/side-nav.service';
import { SpinnerModule } from '../kernel/shared/modules/spinner/spinner.module';
import { direction } from './enums/routeDirection';
import { TranslationModule } from 'src/app/kernel/translations/modules/translation.module';
import { NotAuthorizedComponent } from './error-pages/not-authorized/not-authorized.component';
import { ButtonModule } from "src/app/kernel/shared/modules/button/button.module";
import { RouterModule } from "@angular/router";
import {MessageModule} from "src/app/kernel/tools/message/message.module";
import {ErrorInterceptor} from "src/app/core/interceptors/ErrorInterceptor";
import {HTTP_INTERCEPTORS} from "@angular/common/http";

@NgModule({
  declarations: [
    NotAuthorizedComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    // ===================== Core Imports { =====================
    GraphqlModule.on(environment.graphql_endpoint),
    // ===================== } Core Imports =====================

    // =================== STATE MANAGEMENT { ===================
    StoreModule.forRoot({ appInfo: appInfoStateReducers }),
    EffectsModule.forRoot([ RootEffects ]),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: false,
      autoPause: true,
    }),
    // =================== } STATE MANAGEMENT ===================
    TranslationModule.forRoot('/assets/i18n/root/'),
    PopupWrapperModule,
    SpinnerModule,
    ButtonModule,
    RouterModule,
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
  ],
  exports: [ SideNavModule, PopupWrapperModule, SpinnerModule ],
  bootstrap: [],
})
export class CoreModule {
  constructor(sideNavService: SideNavService) {
    sideNavService.setMainLinks([
      {
        id: 'Home',
        items: [],
        icon: 'home',
        tooltip: 'sidenav.home',
        direction: direction.TOP,
        routerLink: '/',
        routerLinkExact: true
      },
      {
        id: 'Dashboard',
        items: [],
        icon: 'menu',
        tooltip: 'sidenav.dashboard',
        direction: direction.TOP,
      },
      {
        id: 'news',
        items: [],
        icon: 'rss',
        tooltip: 'sidenav.news',
        direction: direction.TOP,
        routerLink: '/news',
        permissions: [ 'VIEW_ARTICLE' ]
      },
      {
        id: 'crm',
        icon: 'crm',
        items: [],
        tooltip: 'sidenav.CRM',
        direction: direction.TOP,
      },
      //TODO Move this to it's section when implemented
      {
        id: 'car-rental-short',
        items: [
          {
            id: 'Calender',
            label: 'crs.calender',
            isActive: false,
            expanded: false,
          },
          {
            id: 'Reservations',
            label: 'crs.reservations',
            isActive: false,
            expanded: false
          },
          {
            id: 'Quotations',
            label: 'crs.quotations',
            isActive: false,
            expanded: false
          },
          {
            id: 'Transactions',
            label: 'crs.transactions',
            isActive: false,
            expanded: false
          },
        ],
        icon: 'approval',
        tooltip: 'sidenav.carRentalShort',
        direction: direction.TOP,
      },
      {
        id: 'fsh_application',
        items: [],
        icon: 'fst',
        tooltip: 'sidenav.fSHApplication',
        direction: direction.TOP,
      },
      {
        id: 'flt',
        icon: 'fst',
        items: [],
        tooltip: 'sidenav.fLTApplication',
        direction: direction.TOP,
        routerLink: '/fleet/vehicle',
        permissions: [ 'VIEW_VEHICLE_LT_ALL', 'VIEW_VEHICLE_LT_OWN' ]
      },

      // -------------------------------------------------------------
      //TODO Move this to it's section when implemented
      {
        id: 'Automations',
        items: [
          {
            id: 'Pricing',
            label: 'auto.pricing',
            isActive: false,
            expanded: false
          },
          {
            id: 'Clousures',
            label: 'auto.clousures',
            isActive: false,
            expanded: false
          },
          {
            id: 'Notification',
            label: 'auto.notification',
            isActive: false,
            expanded: false
          },
        ],
        icon: 'robot',
        tooltip: 'sidenav.automations',
        direction: direction.BOTTOM,
      },
      {
        id: 'chat',
        items: [],
        icon: 'chat',
        tooltip: 'sidenav.chat',
        direction: direction.BOTTOM,
      },
      {
        id: 'notification',
        items: [],
        icon: 'notifications',
        tooltip: 'sidenav.notification',
        direction: direction.BOTTOM,
      },
      {
        id: 'setting',
        items: [],
        icon: 'settings',
        tooltip: 'sidenav.setting',
        direction: direction.BOTTOM,
      },
    ]);
  }
}
