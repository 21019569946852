import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardLayoutComponent } from 'src/app/shared/layouts/dashboard-layout/dashboard-layout.component';
import { AuthGuard } from 'src/app/shared/auth/guards/auth.guard';

/**
 * NOTE THAT THIS FILE SHOULD ONLY CONTAIN LAZY LOADING ROUTS
 * EACH ROUTE SHOULD POINT TO A MODULE THAT CONTAINS ITS OWN ROUTES
 */

const routes: Routes = [
  {
    path: '',
    component: DashboardLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'fleet-management',
        loadChildren: () =>
          import('./modules/fleet-management/fleet-management.module').then(
            (m) => m.FleetManagementModule
          ),
      },
      {
        path: 'fleet',
        loadChildren: () =>
          import('./modules/fleet/fleet.module').then((m) => m.FleetModule),
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class FleetLTRoutingModule {}
