import { Component, Inject, Input, OnInit } from '@angular/core';
import { SideNavService } from 'src/app/shared/layouts/side-nav/services/side-nav.service';
import { SideNavStatusEnum } from 'src/app/shared/layouts/side-nav/interfaces/side-nav-status.enum';
import { Location } from '@angular/common';
import { SidenavModel } from 'src/app/shared/layouts/side-nav/models/sidenav.model';
import { expandNavItemAnimation } from 'src/app/shared/layouts/side-nav/animations/expand-nav-item.animation';
import { AuthService } from 'src/app/shared/auth/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'sxw-side-nav',
  templateUrl: './side-nav.component.html',
  styleUrls: ['./side-nav.component.scss'],
  animations: [expandNavItemAnimation],
})

// TODO: Refactor this component.
export class SideNavComponent implements OnInit {
  @Input() width = '303px';
  @Input() agencyName = 'Galdieri';
  @Input() agencyExtraName = 'Rent';
  @Input() agencyNameStyle = 'agency-name';
  @Input() agencyExtraNameStyle = 'agency-extra-name';

  mainLinks = this.sideNavService.mainLinks$;
  selectedMainItem$ = this.sideNavService.getSelectedMainItem();

  sideNavStatusEnum = SideNavStatusEnum;
  sideNavStatus =
    window.innerWidth < 768
      ? SideNavStatusEnum.collapse
      : SideNavStatusEnum.expanded;
  currentUrl = this.location.path();
  companiesShowMode = false;
  widthScreen = window.innerWidth;

  items = [{ label: 'Logout' }];
  noUser = environment.skip_auth;
  constructor(
    private sideNavService: SideNavService,
    @Inject(Location) private location: Location,
    protected authService: AuthService,
  ) {}

  ngOnInit() {
    /**
     * I did that because sidenav re-init in every Loaded module because DashboardLayout component,
     * make ngOnInit run again
     * TODO: Refactor sidenav to be called once in App root
     */
    if (this.sideNavService.currentActivatedMainLink) {
      return;
    }
    /** .......................................................................................... */
    this.sideNavService.searchMenuActivatedRoute(this.currentUrl);
  }

  showAvailableCompanies() {
    this.companiesShowMode = true;
  }

  changeCompany(company: { name: string; id: string; logo: string }) {
    this.authService.changeCompany(company);
    this.companiesShowMode = false;
  }

  /**
   * change sidebar status
   */
  expandNavbar(): void {
    if (this.sideNavStatus === SideNavStatusEnum.expanded) {
      this.sideNavStatus = SideNavStatusEnum.collapse;
    } else if (this.sideNavStatus === SideNavStatusEnum.collapse) {
      this.sideNavStatus = SideNavStatusEnum.expanded;
    } else if (this.sideNavStatus === SideNavStatusEnum.mouseenter) {
      this.sideNavStatus = SideNavStatusEnum.expanded;
    } else if (this.sideNavStatus === SideNavStatusEnum.mouseleave) {
      this.sideNavStatus = SideNavStatusEnum.expanded;
    }
  }

  setSelectedItem(id: string | null = null): void {
    if (this.widthScreen < 768) this.sideNavStatus = SideNavStatusEnum.expanded;
    this.sideNavService.setSelectedMainLink(id);
  }

  /**
   * change sidebar status to mouseenter or mouseleave
   * @param event
   */
  hoverExpandNavbar(event: MouseEvent): void {
    if (this.sideNavStatus === SideNavStatusEnum.expanded) {
      return;
    }
    this.sideNavStatus =
      event.type === 'mouseenter'
        ? SideNavStatusEnum.mouseenter
        : SideNavStatusEnum.mouseleave;
  }

  /**
   * Toggle expanded menu items (open / close them)
   * @param item
   */
  toggleExpandMenuItem(item: SidenavModel): void {
    this.sideNavService.toggleExpandItem(item);
  }
}
