import { IAuthUser } from 'src/app/shared/auth/interfaces/auth-user.interface';
import AuthUserModelInterface from 'src/app/kernel/shared/interfaces/auth-user-model.interface';

export class AuthUserModel implements AuthUserModelInterface {
  constructor(
    public id: string,
    public name: string,
    public email: string,
    public username: string,
    public image: string,
    public permissions: string[],
    public availableCompanies:
      | { name: string; id: string; logo: string }[]
      | null,
    public activeCompany: { name: string; id: string; logo: string },
    public roles?: { id: string; name: string; key: string }[]
  ) {}

  static create(data: IAuthUser): AuthUserModel {
    const {
      id,
      name,
      email,
      username,
      image = 'default.png',
      roles = [],
      activePermissions = [],
      availableCompanies = [],
      activeCompany,
    } = data;
    return new AuthUserModel(
      id,
      name,
      email,
      username,
      image,
      activePermissions,
      availableCompanies,
      activeCompany,
      roles
    );
  }

  /**
   * Checks if the user has the given permission.
   * @param permission
   */
  public can(permission: string | (string | string[])[]): boolean {
    if (!permission) {
      return true;
    }
    if (this.permissions.includes('SUPER_ADMIN')) {
      return true;
    }
    if (typeof permission === 'string') {
      permission = [permission];
    }
    return this.doPermissionsCheck(this.permissions, permission);
  }

  /**
   * does an or check on permissions, taking and check in consideration.
   * @param owned
   * @param required
   */
  private doPermissionsCheck(
    owned: string[],
    required: (string | string[])[]
  ): boolean {
    if (!required || !required.length) {
      return true;
    }
    return required.some((_permission) =>
      typeof _permission === 'object'
        ? this.doAndPermissionsCheck(owned, <string[]>_permission)
        : owned.indexOf(<string>_permission) !== -1
    );
  }

  /**
   * does an and condition on permissions.
   * @param owned
   * @param required
   */
  private doAndPermissionsCheck(owned: string[], required: string[]): boolean {
    return required.every((_permission) => owned.includes(_permission));
  }

}
