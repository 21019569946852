import { SidenavItemInterface } from '../../shared/layouts/side-nav/interfaces/side-nav-items.interface';
const fleetSTSettingsLinks: SidenavItemInterface[] =  [{
  id: 'short-term',
  label: 'settings.short',
  isActive: false,
  expanded: false,
  items: [
    {
      id: 'types',
      label: 'settings.types',
      isActive: false,
      expanded: false,
      items: [
        {
          id: 'vehicleType',
          label: 'flm.vehicleType',
          isActive: false,
          expanded: false,
          routerLink: '/vehicleType',
          permissions: ['VIEW_VEHICLE_TYPE_ALL', 'VIEW_VEHICLE_TYPE_OWN'],
        },
        {
          id: 'deadline-type',
          label: 'flm.deadlineType',
          isActive: false,
          expanded: false,
          routerLink: '/deadline-type',
          permissions: ['VIEW_DEADLINE_TYPE_ALL', 'VIEW_DEADLINE_TYPE_OWN'],
        },
        {
          id: 'maintenanceType',
          label: 'flm.maintenanceType',
          isActive: false,
          expanded: false,
          routerLink: '/maintenanceType',
          permissions: [
            'VIEW_MAINTENANCE_TYPE_ALL',
            'VIEW_MAINTENANCE_TYPE_OWN',
          ],
        },
        {
          id: 'expenseType',
          label: 'flm.expenseType',
          isActive: false,
          expanded: false,
          routerLink: '/expenseType',
          permissions: ['VIEW_EXPENSE_TYPE_ALL', 'VIEW_EXPENSE_TYPE_OWN'],
        },
        {
          id: 'movement-reason',
          label: 'flm.movementReason',
          isActive: false,
          expanded: false,
          routerLink: '/movement-reason',
          permissions: [
            'VIEW_MOVEMENT_REASON_ALL',
            'VIEW_MOVEMENT_REASON_OWN',
          ],
        },
      ],
    },
    {
      id: 'feature',
      label: 'flm.feature',
      isActive: false,
      expanded: false,
      routerLink: '/feature',
      permissions: [
        'VIEW_FEATURE_ALL', 'VIEW_FEATURE_OWN',
        'VIEW_FEATURE_CATEGORY_ALL', 'VIEW_FEATURE_CATEGORY_OWN'
      ],
    },
    {
      id: 'vehicle-class',
      label: 'flm.vehicleClass',
      isActive: false,
      expanded: false,
      routerLink: '/vehicle-class',
      permissions: ['VIEW_VEHICLE_CLASS_ALL', 'VIEW_VEHICLE_CLASS_OWN'],
    },
    {
      id: 'insurance',
      label: 'flm.insurance',
      isActive: false,
      expanded: false,
      routerLink: '/insurance',
      permissions: ['VIEW_INSURANCE_ALL', 'VIEW_INSURANCE_OWN'],
    },
    {
      id: 'optionalExtra',
      label: 'flm.optionalExtra',
      isActive: false,
      expanded: false,
      routerLink: '/optionalExtra',
      permissions: ['VIEW_OPTIONAL_EXTRA_ALL', 'VIEW_OPTIONAL_EXTRA_OWN'],
    },
    {
      id: 'additionalCost',
      label: 'flm.additionalCost',
      isActive: false,
      expanded: false,
      routerLink: '/additionalCost',
      permissions: ['VIEW_ADDITIONAL_COST_ALL', 'VIEW_ADDITIONAL_COST_OWN'],
    },
    {
      id: 'damageManagement',
      label: 'flm.damageManagement',
      isActive: false,
      expanded: false,
      routerLink: '/damage',
      permissions: [
        'VIEW_DAMAGE_CONFIG_ALL', 'VIEW_DAMAGE_CONFIG_OWN',
        'VIEW_DAMAGE_TYPE_ALL', 'VIEW_DAMAGE_TYPE_OWN',
        'VIEW_DAMAGE_LEVEL_ALL', 'VIEW_DAMAGE_LEVEL_OWN'
      ],
    },
    {
      id: 'kilometer',
      label: 'flm.kilometer',
      isActive: false,
      expanded: false,
      routerLink: '/kilometer',
      permissions: ['VIEW_KILOMETER_ALL', 'VIEW_KILOMETER_OWN'],
    },
    {
      id: 'pricing-list',
      label: 'flm.pricingList',
      isActive: false,
      expanded: false,
      routerLink: '/pricing-list',
      permissions: ['VIEW_PRICING_LIST_ALL', 'VIEW_PRICING_LIST_OWN'],
    },
    {
      id: 'seasons',
      label: 'flm.seasons',
      isActive: false,
      expanded: false,
      routerLink: '/seasons',
      permissions: ['VIEW_SEASON_ALL', 'VIEW_SEASON_OWN'],
    },
  ],
}]
export default {fleetSTSettingsLinks}
